import React from 'react';
import { useIsMobile, useRetailerLike, useCatalogV2 } from '@hooks/common';
import { Box, Container, Divider, Grid, Stack } from '@mui/material';
import { Skeleton } from '@components/Common';
import { GammaTheme } from '@themes';
import { Product } from '@components/Catalog';

export const useGridSkeleton = (itemsCount: number | undefined) => {
    const
        gridLimit = Number(process.env.GATSBY_GRID_LIMIT),
        toShow = Math.min(itemsCount || gridLimit, gridLimit),
        catalogV2 = useCatalogV2()

    return (
        <Grid container spacing={ 2 } className="grid-list">
            { Array(toShow).fill(null).map((_, i) => (
                <Grid
                    item
                    xs={ 12 }
                    sm={ catalogV2 ? 9 : 8 }
                    md={ catalogV2 ? 6 : 9 }
                    lg={ catalogV2 ? 4 : 6 }
                    xl={ catalogV2 ? 3 : 4 }
                    key={ `grid_item_${ i }` }>
                    <Product key={ `product_${ i }` } data={ {} } thickness_variants={ {} }/>
                </Grid>
            )) }
        </Grid>
    );
};

export const useProductImageSkeleton = (height = 192) => (
    <Skeleton variant="rounded" width={ '100%' } height={ height }/>
);

export const useProductHeaderSkeleton = () => {
    if (useRetailerLike(['Gamma', 'GammaBe', 'Karwei']))
        return (
            <Stack direction="column">
                <Stack direction="row" justifyContent="space-between">
                    <Box sx={ { pr: '10px' } } className="catalog-product-skeleton">
                        <Skeleton height={ 21 } width={ 100 }/>
                        <Skeleton height={ 32 } width={ 190 }/>
                    </Box>
                </Stack>
            </Stack>
        );

    return (
        <Stack direction="column">
            <Stack direction="row" justifyContent="space-between">
                <Box sx={ { pr: '10px' } }>
                    <Skeleton height={ 16 } width={ 100 } sx={ { mb: 1 } }/>
                    <Skeleton height={ 32 } width={ 190 }/>
                </Box>
            </Stack>
        </Stack>
    );
}

export const useProductDimensionsSkeleton = () => {
    if (useRetailerLike([ 'Gamma', 'GammaBe', 'Karwei' ]))
        return <Skeleton height={ 21 } width={ 100 } sx={ { mt: 1, mb: '15px' } }/>;

    return <Skeleton height={ 24 } width={ 100 } sx={ { mt: 1 } }/>;
};

export const useProductVariantsSkeleton = () => {
    if (useRetailerLike([ 'Gamma', 'GammaBe', 'Karwei' ]))
        return <Skeleton height={ 21 } width={ 190 } sx={ { mb: '20px' } }/>;

    return (
        <Box>
            <Skeleton height={ 15 } width={ 190 } sx={ { mt: '17px' } }/>
            <Stack direction="row" sx={ { mt: 1, mb: 1 } }>
                <Skeleton height={ 40 } width={ 50 } sx={ { mr: 1 } }/>
                <Skeleton height={ 40 } width={ 50 } sx={ { mr: 1 } }/>
                <Skeleton height={ 40 } width={ 50 }/>
            </Stack>
        </Box>
    );
};

export const useGoToProductButtonSkeleton = () => {
    if (useRetailerLike([ 'Gamma', 'GammaBe', 'Karwei' ]))
        return <Skeleton height={ 40 } width={ 90 } sx={ { marginLeft: 'auto!important' } }/>;

    return <Skeleton height={ 40 } width={ 50 } sx={ { marginLeft: 'auto!important' } }/>;
}

export const useSawListButtonSkeleton = () => {
    if (useRetailerLike([ 'Gamma', 'GammaBe', 'Karwei' ]))
        return (
            <Stack className="sawlist-btn">
                <Skeleton
                    height={ 80 }
                    width="100%"
                    sx={ {
                        mt: '-80px',
                        borderRadius: '24px',
                        [GammaTheme.breakpoints.down('lg')]: {
                            mt: '-20px',
                            mb: '20px'
                        }
                    } }
                />
            </Stack>
        );

    return (
        <Stack className="sawlist-btn">
            <Skeleton height={ 48 } width="100%" sx={ { position: 'absolute', mt: '-62px' } }/>
        </Stack>
    );
};

export const usePaginationSkeleton = () => {
    if (useRetailerLike([ 'Gamma', 'GammaBe', 'Karwei' ]))
        return <Skeleton width={ 150 } height={ 60 }/>;

    return <Skeleton width={ 150 } height={ 40 }/>;
};

export const useWizardSkeleton = () => {
    if (useRetailerLike('Insects')) {
        const isMobile = useIsMobile();

        return (
            <Container maxWidth="xl" className="wizard-container">
                <Stack direction="column">
                    <Skeleton className="question-title" height={ 36 } width={ 200 }/>
                    <Skeleton className="question-description" height={ 56 } width={ 150 }/>
                    <Stack direction="row" className="answers">
                        { (isMobile ? [ 1 ] : [ 1, 2 ]).map((i) =>
                            <Stack className="answer" key={ i } width={ 332 }>
                                { useProductImageSkeleton(210) }
                                <Stack className="answer-content" padding={ '16px' }>
                                    <Skeleton height={ 36 } width={ 200 }/>
                                    <Skeleton height={ 40 } width="auto"/>
                                    <Skeleton height={ 40 } width={ 100 }/>
                                </Stack>
                            </Stack>
                        ) }
                    </Stack>
                    <Divider/>
                    { !isMobile &&
                        <Stack direction="row" className="control-container">
                            <Stack direction="row" className="controls control-left">
                                <Skeleton height={ 48 } width={ 100 }/>
                                <Divider orientation="vertical" flexItem/>
                            </Stack>
                            <Stack direction="row" className="controls">
                                <Skeleton height={ 48 } width={ 100 }/>
                                <Skeleton height={ 48 } width={ 100 }/>
                            </Stack>
                        </Stack>
                    }
                    { isMobile &&
                        <Stack direction="row" className="control-container">
                            <Stack direction="row" className="controls control-left">
                                <Skeleton height={ 48 } width={ 100 }/>
                                <Skeleton height={ 48 } width={ 100 }/>
                            </Stack>
                            <Stack direction="row" className="controls">
                                <Skeleton height={ 48 } width="100%"/>
                            </Stack>
                        </Stack>
                    }
                </Stack>
            </Container>
        );
    }

    return;
};

export const useFilterSkeleton = values => {
    if (useRetailerLike([ 'Gamma', 'GammaBe', 'Karwei' ]))
        return (
            <Box sx={ { mb: '35px' } }>
                <Divider/>
                <Skeleton variant="text" width={ 200 } sx={ { mt: '6px', mb: '9px' } }/>
                <Skeleton variant="text" width={ 100 } sx={ { mb: 1 } }/>
                { values }
            </Box>
        );

    return (
        <Box sx={ { mb: '35px' } }>
            <Divider/>
            <Skeleton variant="text" width={ 200 } sx={ { mt: '6px', mb: '9px' } }/>
            { values }
        </Box>
    );
};

export const useFilterTooltipSkeleton = () => {
    if (useRetailerLike([ 'Gamma', 'GammaBe', 'Karwei' ]))
        return (
            <Stack direction="row">
                <Skeleton variant="circular" width={ 26 } height={ 24 }/>
                <Skeleton variant="text" width={ 150 } sx={ { ml: 1, mb: 1 } }/>
            </Stack>
        );
    return (
        <Skeleton variant="circular" width={ 26 } height={ 24 }/>
    );
};
