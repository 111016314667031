import React from 'react';
import { Box, Divider, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { TitleCollapse, Tooltip } from '@components/Common';
import { useActiveFilterLabel, useActiveFilters, useCatalogContext } from '@hooks/catalog';
import { useIsMobile, useMicrocopy, useCatalogV2 } from '@hooks/common';
import { ActiveFiltersContent } from '@components/Catalog/Filters';
import { RetailerComponent } from '@components';

const ActiveFiltersFilter = ({ onReset }) => {
    const catalogV2 = useCatalogV2();

    if (catalogV2) return;

    const
        microcopy = useMicrocopy(),
        activeFilters = useActiveFilters(),
        isMobile = useIsMobile()


    return (
        <Box>
            <Divider/>
            <TitleCollapse
                title={ microcopy.get('catalog.filter.active_filters.title', 'Active filters') }
                tooltip={
                    <Tooltip
                        title={ microcopy.get('catalog.filter.active_filters.title', 'Active filters') }
                        content={ microcopy.get('catalog.filter.active_filters.tooltip_text', 'Tooltip') }
                    />
                }
                opened={ isMobile ? true : undefined }
            >
                <Box sx={ { mt: '10px' } }>
                    { Object.keys(activeFilters).map(filter => {
                        return (
                            activeFilters[filter]
                                ? <Stack
                                    sx={ { py: '5px', fontSize: 18 } }
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    key={ `active_filter_${filter}` }
                                    className="active-filters"
                                >
                                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={ 1 }>
                                        <Box component="span" sx={ { lineHeight: '1.2' } }>
                                            { useActiveFilterLabel(filter) }:
                                        </Box>
                                        <Box component="span" sx={ { lineHeight: '1.2' } }>
                                            <ActiveFiltersContent filter={ filter }/>
                                        </Box>
                                    </Stack>
                                    <IconButton aria-label="close" onClick={ () => onReset(filter) } sx={ { m: -1, ml: 0 } }>
                                        <CloseIcon/>
                                    </IconButton>
                                </Stack>
                                : ''
                        );
                    }) }
                </Box>
            </TitleCollapse>
        </Box>
    );
};

export default (props: { onReset: any }) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Catalog/Filters',
            name: 'ActiveFiltersFilter',
            element: ActiveFiltersFilter
        } }
    />;
