import { createTheme } from '@mui/material';
import { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import { NoNameTheme } from '@themes';
import Poppins from '@fonts/Poppins-Regular.woff2';
import PoppinsBold from '@fonts/Poppins-Bold.woff2';

const primaryMain = '#13aff0';

const palette = {
    primary: {
        main: primaryMain,
        light: '#e8edee',
        lightTwo: '#e8edee',
        lightThree: '#13aff014',
        dark: '#a7adb2',
        darken: '#767b8d',
    },
    secondary: {
        main: '#45464f',
        dark: '#1b1b1f',
    },
    checklist: {
        main: primaryMain,
        light: '#e8edee',
    },
    grey: {
        100: '#c6c6d0',
        200: '#6d818d',
    },
    green: {
        main: '#0f7426',
    },
    white: {
        main: '#fff',
    },
    discount: {
        text: '#0f7426',
        label: '#0f7426',
    },
    icons: {
        usp: primaryMain,
    },
    wizard: {
        dark: '#f99d27',
    },
};

let configurator = {
    options: {
        detailed_card: {
            border: {
                primary: palette.grey[100],
                active: palette.secondary.dark,
            },
        },
    },
    cta: {
        main: {
            background: {
                primary: palette.primary.main,
            },
            text: {
                primary: palette.white.main,
            },
        },
    },
    tooltip: {
        text: {
            primary: palette.primary.main,
        },
    },
    checkbox: {
        active: palette.primary.main,
    },
};

let paletteTheme = {
    ...palette,
    configurator: {
        ...configurator,
    },
};

let flexscreenInsectsTheme = createTheme({
    palette: {
        ...paletteTheme,
    },
    typography: {
        ...createFontFamily(
            'Poppins',
            {
                fontSize: 14,
            },
        ),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 430,
            md: 850,
            lg: 991,
            xl: 1368,
        },
    },
});

flexscreenInsectsTheme = createTheme(flexscreenInsectsTheme, {
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'Poppins';
                  src: url(${ Poppins }) format('woff2');
                }
                @font-face {
                  font-family: 'PoppinsBold';
                  src: url(${ PoppinsBold }) format('woff2');
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `,
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-contained': {
                        borderRadius: '100px',
                        color: palette.white.main,
                        '&.go-to-product': {
                            height: '48px',
                            padding: '15px',
                        },
                    },
                },
            },
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    '&.catalog-product-price': {
                        '.combined-price': {
                            h5: {
                                '.price': {
                                    span: {
                                        fontWeight: 400,
                                    },
                                },
                            },
                        },
                    },
                    '.combined-price': {
                        '.price-text': {
                            fontSize: '0.875rem',
                        },
                        h5: {
                            '.price': {
                                span: {
                                    fontWeight: 700,
                                    fontSize: '1.375rem',
                                },
                            },
                        },
                    },
                    '.sawlist-product-image': {
                        borderRadius: 16,
                        overflow: 'hidden',
                    },
                    '&.order-overview': {
                        borderColor: palette.grey[100],
                        '.discount-info': {
                            color: palette.secondary.main,
                            fontWeight: 700,
                        },
                    },
                    '&.order-overview-top': {
                        '>p': {
                            strong: {
                                fontSize: '1.375rem',
                            },
                        },
                        '.sawlist-row': {
                            p: {
                                fontWeight: 700,
                                color: palette.secondary.main,
                            },
                        },
                        '.sawlist-total': {
                            strong: {
                                fontSize: '1.375rem',
                            },
                            '.combined-price': {
                                color: palette.secondary.main,
                            },
                        },
                    },
                    '&.quantity-controls .quantity-wrapper': {
                        borderRadius: 0,
                        borderColor: palette.grey[100],
                        '.MuiButtonBase-root, .MuiTypography-root': {
                            width: 48,
                            height: 48,
                        },
                    },
                    '&.dimensions-slider': {
                        flex: 1,
                        '.MuiSlider-track': {
                            color: palette.primary.dark,
                        },
                    },
                    '&.dimensions-slider-wrapper': {
                        marginLeft: '15px',
                    },
                    '&.delivery-info': {
                        '.delivery-icon': {
                            color: palette.primary.main,
                        },
                    },
                    '.price-slider': {
                        '.MuiSlider-track': {
                            color: palette.primary.dark,
                        },
                    },
                    '.dimentions-input': {
                        maxWidth: '60px',
                        height: '50px',
                        input: {
                            minHeight: 26,
                            fontSize: '1rem',
                            padding: '12px 10px',
                            textAlign: 'center',
                        },
                    },
                    '.product-image': {
                        '.lazy-load-image-background': {
                            img: {
                                height: 300,
                            },
                        },
                    },
                    '&.no-configurator-wrapper': {
                        [flexscreenInsectsTheme.breakpoints.up('md')]: {
                            padding: 24,
                        },
                    },
                    '.terms-conditions-wrapper': {
                        margin: '16px 0 -14px',
                    },
                    '.gallery-wrapper': {
                        borderRadius: 16,
                        overflow: 'hidden',
                    },
                    '.price': {
                        display: 'inline-flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'center',
                        '.price-symbol': {
                            top: 0,
                            left: 5,
                            fontSize: '1.5rem !important',
                            fontWeight: '100 !important'
                        }
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.product-dimensions-gamma': {
                        fontSize: '1rem',
                    },
                    '&.go-back-sawlist': {
                        fontSize: 14,
                        textDecoration: 'none',
                        color: palette.primary.main,
                        svg: {
                            marginRight: 12,
                        },
                        strong: {
                            fontWeight: 400,
                        },
                    },
                    '&.with-discount .price span': {
                        color: palette.green.main,
                    },
                    '&.header-title': {
                        fontSize: '1.805rem',
                    },
                    '&.header-description': {
                        fontSize: '1.042rem',
                        textAlign: 'center',
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    '&.product-card': {
                        borderRadius: 12,
                        borderColor: palette.grey[100],
                        '.product-sizes': {
                            fontWeight: 700,
                            fontSize: '1.375rem',
                        },
                    },
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {},
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    '&.sawlist-left': {
                        '.sawlist-header': {
                            border: 'none',
                            padding: '16px 0',
                            '.sawlist-title': {
                                p: {
                                    '&:first-of-type': {
                                        fontSize: '1.75rem',
                                    },
                                },
                            },
                            '.add-to-sawlist-gamma': {
                                borderColor: palette.grey[200],
                                borderRadius: 100,
                                p: {
                                    fontSize: 14,
                                },
                                [flexscreenInsectsTheme.breakpoints.up('sm')]: {
                                    minWidth: 350,
                                    height: 66,
                                },
                            },
                        },
                    },
                    '.sawlist-product-wrapper': {
                        border: `1px solid ${ palette.grey[100] }`,
                        borderRadius: 16,
                        padding: '0 16px',
                        marginBottom: 10,
                        [flexscreenInsectsTheme.breakpoints.down('lg')]: {
                            padding: 16,
                        },
                        '&+hr': {
                            display: 'none',
                        },
                        '.sawlist-product-controls': {
                            [flexscreenInsectsTheme.breakpoints.down('sm')]: {
                                paddingLeft: 0,
                            },
                        },
                    },
                    '&.catalog-grid.v2, &#pdp-wrapper.v2': {
                        '.promo-label-v2.discount-label-v2': {
                            backgroundColor: palette.green.main,
                            color: palette.white.main
                        },
                        '.promo-label': {
                            fontSize: '0.9rem'
                        },
                        '.catalog-product-price ': {
                            '.discount-number': {
                                fontFamily: 'PoppinsBold',
                            },
                            '.discount-text': {
                                fontFamily: 'PoppinsBold',
                            }
                        },
                        '.combined-price': {
                            '.discount-price': {
                                color: palette.primary.dark,
                                '&:after': {
                                    background: palette.green.main
                                }
                            },
                            '.price': {
                                '& > .MuiTypography-h5': {
                                    fontFamily: 'PoppinsBold',
                                },
                            }
                        },
                        '.product-title, .pdp-title': {
                            fontWeight: 700,
                            fontSize: '1.375rem',
                        },
                        '.delivery-info': {
                            p: {
                                fontSize: '0.85rem',
                            },
                        },
                    },
                    '&.catalog-grid': {
                        // '&:not(.v2)': {
                            '.catalog-block': {
                                '.MuiGrid-container': {
                                    '>.MuiGrid-item': {
                                        [flexscreenInsectsTheme.breakpoints.up('lg')]: {
                                            maxWidth: '50%',
                                            flexBasis: '50%',
                                        },
                                    },
                                },
                                '.price': {
                                    '.price-symbol': {
                                        fontWeight: '700 !important'
                                    }
                                },
                            // },
                        },
                        '&.v2': {
                            '.filter-block': {
                                '.catalog-filter-content': {

                                    '.active-filter-button button.MuiButton-root': {
                                        backgroundColor: palette.primary.lightThree,
                                    }
                                },
                            },
                        }
                    },
                    '&#pdp-wrapper': {
                        '&.v2' : {
                            '.configurator.total-price': {
                                '.MuiTypography-body1 strong': {
                                    fontSize: '1.3rem',
                                }
                            },
                            'div.combined-price': {
                                '.price-text': {
                                    marginLeft: 0,
                                }
                            },
                            '.pdp-promo-accordion': {
                                marginBottom: 20,
                                '.pdp-promo-accordion-button': {
                                    '.promo-label': {
                                        paddingLeft: 8,
                                        paddingRight: 8
                                    },
                                    'svg': {
                                        fill: primaryMain
                                    }
                                }
                            },
                            '.MuiCollapse-wrapperInner': {
                                fontSize: '0.9rem',
                                lineHeight: 1.3,
                                fontWeight: 'normal'
                            }
                        },
                        'div.combined-price': {
                            marginBottom: 16,
                            '.price-text': {
                                marginTop: 7,
                                marginLeft: 12,
                            },
                            'small': {
                                verticalAlign: 'top'
                            }
                        },
                    },
                    '&.pdp-content.no-configurator': {
                        marginTop: 0,
                    },
                },
            },
        },
    },
});

let theme: any = createTheme(NoNameTheme, flexscreenInsectsTheme);

export default createTheme(theme, ThemeComponents(theme));
