import React from 'react';
import { Chip, Grid } from '@mui/material';
import { replace, split } from 'lodash';
import { useActiveFilters } from '@hooks/catalog';

const ThicknessFilter = ({ data, onChange }) => {
    const activeFilters = useActiveFilters();

    return (
        <Grid container spacing={ 1 } mt={ 0.5 }>
            { data.values
                  .map(value => (
                      <Grid
                          item
                          xs={ 3 }
                          sm={ 2 }
                          md={ 4 }
                          lg={ 3 }
                          xl={ 2 }
                          key={ `product_thickness_${ replace(value.title, ' ', '_') }` }
                      >
                          <Chip
                              label={ value.title }
                              variant={ !split(activeFilters?.['product_thickness'], ',').includes(value.title) ? 'outlined' : undefined }
                              clickable
                              onClick={ () => onChange(value.title) }
                          />
                      </Grid>
                  )) }
        </Grid>
    );
};

export default ThicknessFilter;
