import { omitBy, isNil } from 'lodash';
import type {
    Configuration,
    ConfigurationValue,
    Configurator,
    ConfiguratorDimensionsOption,
    ConfiguratorDimensionsOptionValue,
    ConfiguratorInputOption,
    ConfiguratorInputOptionValue,
    ConfiguratorOption,
    ConfiguratorOptionValue,
    Option,
    Price,
    PriceMatrixKey,
    RetailerValue,
    SettingsFor3dView,
    SimpleConfigurationValue,
} from '@models';
import { copy } from './commonHelper';

export const MATERIAL_OPTION_CODE = 'material';

export const filterByConditions = <T extends Option | ConfiguratorOptionValue>(items: T[], configuration: Configuration): T[] =>
    items.filter(item => {
        if (!item.conditions)
            return true;

        for (const condition of item.conditions)
            if (!configuration[condition.code] || !condition.values.some(value =>
                configurationValueWrapperResolver(configuration[condition.code])?.compareWithValue(value.code),
            ))
                return false;

        return true;
    });

export const calculateActiveConfigurator = (configurator: Configurator, configuration: Configuration): Configurator => ({
    ...configurator,
    steps: configurator.steps.map(step => ({
        ...step,
        options: filterByConditions(step.options, configuration).map(option =>
            optionWrapperResolver(option).calculateActiveOption(configuration),
        ),
    })),
});

export const makeDefaultConfiguration = (configurator: Configurator, material: {
    code: string,
    value: string
}, channel: string): Configuration => {
    const result = { retailer: { channel } };

    for (const step of configurator.steps)
        for (const option of step.options)
            result[option.code] = optionWrapperResolver(option).getInitialValue(material);

    return omitBy(result, isNil);
};

export const syncConfiguration = (configuration: Configuration, configurator: Configurator, channel: string): Configuration => {
    const result = { retailer: { channel } };

    for (const step of configurator.steps)
        for (const option of step.options)
            result[option.code] = optionWrapperResolver(option).validatedValue(configuration);

    return omitBy(result, isNil);
};

export const calculatePrice = (configuration: Configuration, configurator: Configurator) => {
    let total = 0;

    for (const step of configurator.steps)
        for (const option of step.options)
            total += optionWrapperResolver(option).getPriceValue(configuration);

    return total;
};

export const getPriceValue = (optionValue: ConfiguratorOptionValue | undefined, configuration: Configuration, price?: Price): number => {
    if (!price) return 0;

    if (!optionValue) {
        console.warn('Option Value must exist. Configuration is out of sync with Configurator');
        return 0;
    }
    const priceValue = price.values.find(item =>
        item.type === 'configuratorOptionValue'
            ? item.code === optionValue.code
            : item.code === optionValue.group?.code,
    );

    if (!priceValue) return 0;

    const priceMatrix = priceValue.matrix.find(item =>
        item.keys.every((key, index) =>
            configurationValueWrapperResolver(configuration[price.depends[index].code])?.compareWithKey(key),
        ),
    );

    return priceMatrix?.value || 0;
};

export const getSettingsFor3dView = (configurator: Configurator, configuration: Configuration) => {
    const settings: SettingsFor3dView = {};

    configurator.steps.forEach(step =>
        step.options.forEach(option => {
            const settingsFor3dView = optionWrapperResolver(option).getSettingsFor3dView(configuration);

            if (settingsFor3dView)
                Object.entries(settingsFor3dView)
                    .filter(([ , value ]) => value !== null)
                    .forEach(([ key, value ]) => settings[key] = value);
        }),
    );

    return settings;
};

const optionWrapperResolver = (option: Option): OptionWrapper => {
    switch (option.__typename) {
        case 'ContentfulConfiguratorOption':
            return new ConfiguratorOptionWrapper(option);
        case 'ContentfulConfiguratorDimensionsOption':
            return new ConfiguratorDimensionsOptionWrapper(option);
        case 'ContentfulConfiguratorInputOption':
            return new ConfiguratorInputOptionWrapper(option);
    }
};

export const configurationValueWrapperResolver = (value: ConfigurationValue): ConfigurationValueWrapper | null => {
    if (typeof value === 'string') return new InputValueWrapper(value);

    if (!value) return null;

    if ('channel' in value) return new RetailerWrapper(value);

    if ('code' in value) return new OptionValueWrapper(value);

    if ('width' in value) return new DimensionsValueWrapper(value);

    return null;
};

abstract class OptionWrapper {
    abstract getInitialValue(material?: { code: string, value: string }): ConfigurationValue | null;

    abstract validatedValue(configuration: Configuration): ConfigurationValue | null;

    abstract getPriceValue(configuration: Configuration): number;

    abstract calculateActiveOption(configuration: Configuration): Option;

    abstract getSettingsFor3dView(configuration: Configuration): SettingsFor3dView | undefined;
}

class ConfiguratorOptionWrapper extends OptionWrapper {
    constructor(protected readonly option: ConfiguratorOption) {
        super();
    }

    getInitialValue(material?: { code: string, value: string }): ConfiguratorOptionValue {
        let value: ConfiguratorOptionValue | undefined;

        if (material && this.option.code === material.code)
            value = this.option.values.find(value => value.code === material.value);

        return value || this.option.defaultValue || this.option.values[0];
    }

    validatedValue(configuration: Configuration) {
        return this.option.values.find(item => item.code === this.value(configuration)?.code)
            || this.getInitialValue();
    }

    getPriceValue(configuration: Configuration): number {
        return getPriceValue(this.value(configuration), configuration, this.option.price);
    }

    calculateActiveOption(configuration: Configuration): Option {
        return {
            ...this.option,
            values: filterByConditions(this.option.values, configuration).map(value => ({ ...value })),
        };
    }

    getSettingsFor3dView(configuration: Configuration): SettingsFor3dView | undefined {
        return this.option.values.find(value => value.code === this.value(configuration)?.code)?.settingsFor3dView;
    }

    private value(configuration: Configuration): ConfiguratorOptionValue | undefined {
        return configuration[this.option.code] as ConfiguratorOptionValue;
    }
}

class ConfiguratorDimensionsOptionWrapper extends OptionWrapper {
    constructor(protected readonly option: ConfiguratorDimensionsOption) {
        super();
    }

    getInitialValue(): ConfiguratorDimensionsOptionValue {
        return {
            width: this.option.dimensions.width.min,
            height: this.option.dimensions.height.min,
        };
    }

    validatedValue(configuration: Configuration) {
        const dimensions = configuration[this.option.code] || this.getInitialValue();

        for (const condition of this.option.dimensions.conditions) {
            const thresholdOption = dimensions[condition.thresholdOption];
            const option = dimensions[condition.option];

            switch (true) {
                case condition.thresholdOperator === '<' && thresholdOption < condition.thresholdValue && condition.operator === 'min' && option < condition.value:
                    dimensions[condition.option] = condition.value;
                    break;
                case condition.thresholdOperator === '<' && thresholdOption < condition.thresholdValue && condition.operator === 'max' && option > condition.value:
                    dimensions[condition.option] = condition.value;
                    break;
                case condition.thresholdOperator === '>' && thresholdOption > condition.thresholdValue && condition.operator === 'min' && option < condition.value:
                    dimensions[condition.option] = condition.value;
                    break;
                case condition.thresholdOperator === '>' && thresholdOption > condition.thresholdValue && condition.operator === 'max' && option > condition.value:
                    dimensions[condition.option] = condition.value;
                    break;
            }
        }

        return dimensions;
    }

    getPriceValue(configuration: Configuration): number {
        const value = this.value(configuration);
        const depends = this.option.dimensions.depends;
        const prices = this.option.dimensions.prices;

        if (!value) return 0;

        const priceItem = depends.length === 1
            ? prices.find(item =>
                configurationValueWrapperResolver(configuration[depends[0].code])?.compareWithValue(item.key.code),
            )
            : this.option.dimensions.prices[0];

        if (!priceItem) return 0;

        const priceMatrix = priceItem.matrix.find(item =>
            value.width >= item.width.min
            && value.width <= item.width.max
            && value.height >= item.height.min
            && value.height <= item.height.max,
        );

        if (!priceMatrix) return 0;

        return priceMatrix.value || 0;
    }

    calculateActiveOption(configuration: Configuration): Option {
        const dimensions = configuration[this.option.code] || this.getInitialValue();
        const newOption = copy(this.option);

        for (const condition of this.option.dimensions.conditions) {
            const thresholdOption = dimensions[condition.thresholdOption];
            let value = this.option.dimensions[condition.option][condition.operator];

            switch (true) {
                case condition.thresholdOperator === '<' && thresholdOption < condition.thresholdValue:
                    value = condition.value;
                    break;
                case condition.thresholdOperator === '>' && thresholdOption > condition.thresholdValue:
                    value = condition.value;
                    break;
            }

            newOption.dimensions[condition.option][condition.operator] = value;
        }

        return newOption;
    }

    getSettingsFor3dView(configuration: Configuration): SettingsFor3dView | undefined {
        return undefined;
    }

    private value(configuration: Configuration): ConfiguratorDimensionsOptionValue | undefined {
        return configuration[this.option.code] as ConfiguratorDimensionsOptionValue;
    }
}

class ConfiguratorInputOptionWrapper extends OptionWrapper {
    constructor(protected readonly option: ConfiguratorInputOption) {
        super();
    }

    getInitialValue(): ConfiguratorInputOptionValue {
        return '';
    }

    validatedValue(configuration: Configuration) {
        return configuration[this.option.code] || this.getInitialValue();
    }

    getPriceValue(configuration: Configuration): number {
        return 0;
    }

    calculateActiveOption(configuration: Configuration): Option {
        return {
            ...this.option,
        };
    }

    getSettingsFor3dView(configuration: Configuration): SettingsFor3dView | undefined {
        return undefined;
    }
}

abstract class ConfigurationValueWrapper {
    abstract compareWithValue(value: string): boolean;

    abstract compareWithKey(key: PriceMatrixKey): boolean;

    abstract toSimpleValue(): SimpleConfigurationValue;

    abstract toString(): string;
}

class OptionValueWrapper extends ConfigurationValueWrapper {
    constructor(protected readonly value: ConfiguratorOptionValue) {
        super();
    }

    compareWithKey(key: PriceMatrixKey): boolean {
        return key.type === 'configuratorOptionValue'
            ? key.code === this.value.code
            : key.code === this.value.group?.code;
    }

    compareWithValue(value: string): boolean {
        return this.value.code === value;
    }

    toSimpleValue(): { code: string; group: string | null } {
        return {
            code: this.value.code,
            group: this.value.group?.code || null,
        };
    }

    toString(): string {
        return this.value.code;
    }
}

class RetailerWrapper extends ConfigurationValueWrapper {
    constructor(protected readonly value: RetailerValue) {
        super();
    }

    compareWithKey(key: PriceMatrixKey): boolean {
        return key.code === this.value.channel;
    }

    compareWithValue(value: string): boolean {
        return this.value.channel === value;
    }

    toSimpleValue(): null {
        return null;
    }

    toString(): string {
        return this.value.channel;
    }
}

class DimensionsValueWrapper extends ConfigurationValueWrapper {
    constructor(protected readonly value: ConfiguratorDimensionsOptionValue) {
        super();
    }

    compareWithKey(key: PriceMatrixKey): boolean {
        return false;
    }

    compareWithValue(value: string): boolean {
        return false;
    }

    toSimpleValue(): { width: number; height: number } {
        return {
            width: this.value.width,
            height: this.value.height,
        };
    }

    toString(): string {
        return `${ this.value.width } x ${ this.value.height }`;
    }
}

class InputValueWrapper extends ConfigurationValueWrapper {
    constructor(protected readonly value: string) {
        super();
    }

    compareWithKey(key: PriceMatrixKey): boolean {
        return false;
    }

    compareWithValue(value: string): boolean {
        return false;
    }

    toSimpleValue(): string {
        return this.value;
    }

    toString(): string {
        return this.value;
    }
}
