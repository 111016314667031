import React from 'react';
import { Typography, Stack } from '@mui/material';
import { useMicrocopy, useRetailerLike, useCatalogV2 } from '@hooks/common';
import { ReactComponent as GammaDiscountIcon } from '@images/retailer/Gamma/discount_card.svg';

export const DiscountIcon = () => {
    if (useRetailerLike([ 'Gamma', 'GammaBe' ]))
        return <GammaDiscountIcon width={ 20 } style={ { margin: '-4 0 0 4' } }/>;

    return;
};

export const DiscountLabel = ({ discount }) => {
    if (!discount)
        return;

    const microcopy = useMicrocopy(),
        catalogV2 = useCatalogV2();

    return (
        <>
            { catalogV2
                ? <Stack className="promo-label-v2 discount-label-v2">
                    <span className="discount-number">
                        { discount }%
                    </span>
                    <span className="discount-text">
                        { microcopy.get('catalog.price.discount', 'discount') }
                    </span>
                </Stack>
                : <Typography variant="caption" className="promo-label discount-label">
                    { `${ discount }% ${ microcopy.get('catalog.price.discount', 'discount') }` }
                    <DiscountIcon/>
                </Typography>
            }
        </>
    )
        ;
};
