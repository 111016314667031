import { createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import Overpass from '@fonts/Overpass-Regular.woff2';

const primaryMain = '#342516';

let palette = {
    text: {
        primary: '#212529',
    },
    primary: {
        main: primaryMain,
        light: '#e8edee',
        lightTwo: '#dddad8',
        dark: '#a8a8a8',
        darken: '#767b8d',
    },
    gray: {
        main: '#00000061',
        light: '#d2d2d2'
    },
    secondary: {
        main: '#dce9f7',
    },
    checklist: {
        main: primaryMain,
        light: '#e8edee',
    },
    grey: {
        100: '#e8edee',
        300: '#00000061',
    },
    wizard: {
        dark: primaryMain,
    },
    discount: {
        text: '#ba5d00',
        label: primaryMain,
    },
    icons: {
        usp: '#1976d2'
    }
};

let configurator = {
    toggleMore: {
        text: {
            primary: palette.primary.main
        }
    },
    tooltip: {
        text: {
            primary: palette.primary.main
        }
    },
    checkbox: {
        active: '#1976d2'
    },
    cta: {
        main: {
            background: {
                primary: '#ba5d00'
            },
            text: {
                primary: '#fff'
            }
        }
    },
    options: {
        button: {
            border: {
                active: palette.primary.main
            }
        },
        chip: {
            background: {
                active: palette.primary.main
            },
            border: {
                active: palette.primary.main
            },
            text: {
                active: '#fff'
            }
        },
        radio: {
            border: {
                active: palette.primary.main
            },
            control: {
                active: palette.primary.main
            },
        },
        card: {
            border: {
                active: palette.primary.main
            }
        },
        check: {
            border: {
                active: palette.primary.main
            }
        },
        tile: {
            border: {
                active: palette.primary.main
            },
            mark: {
                background: {
                    primary: palette.primary.main,
                    active: palette.primary.main
                }
            },
            label: {
                background: {
                    primary: palette.primary.lightTwo,
                    active: palette.primary.lightTwo
                },
                text: {
                    primary: '#000',
                    active: '#000'
                }
            }
        },
        slider: {
            rail: {
                background: {
                    primary: palette.primary.lightTwo
                }
            },
            mark: {
                background: {
                    primary: palette.primary.main
                }
            },
            thumb: {
                background: {
                    primary: palette.primary.main
                }
            },
            valueLabel: {
                background: {
                    primary: palette.primary.main
                },
            }
        },
    }
}

let paletteTheme =  {
    ...palette,
    configurator: {
        ...configurator
    }
}

let wiahTheme = {
    palette: {
        ...paletteTheme
    },
    typography: {
        ...createFontFamily('Overpass', {
            fontSize: 18,
            h4: {
                fontSize: '2rem',
            },
            h5: {
                fontSize: '1.5rem',
            },
        }),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'Overpass';
                  src: url('${ Overpass }') format('woff2');
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.rounded': {
                        borderRadius: '25px !important',
                        padding: '9px 20px 7px !important',
                        textTransform: 'uppercase',
                        fontSize: '0.9rem',
                        fontWeight: 700
                    },
                }
            }
        }
    }
}

let theme: any = createTheme(BaseTheme, wiahTheme);

export default createTheme(theme, ThemeComponents(theme));
