import React from 'react';
import { Box, Popover, Stack, ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useMicrocopy } from '@hooks/common';
import type { ContentfulGridFilter } from '@models';
import { CatalogFilterProvider } from '@context/CatalogFilterContext';
import { Tooltip } from '@components/Common';

interface FilterV2Props {
    isSelected: boolean;
    content: ContentfulGridFilter;
    children: React.ReactNode;
    onApply: () => void;
    onReset: () => void;
}

const FilterV2 = ({ isSelected, content, children, onApply, onReset }: FilterV2Props) => {
    const
        buttonRef = React.useRef(),
        [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null),
        [ hasSelectedValues, setHasSelectedValues ] = React.useState(false),
        open = Boolean(anchorEl),
        microcopy = useMicrocopy();

    const
        handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(buttonRef.current || null),
        handleClose = () => setAnchorEl(null),
        handleReset = () => {
            handleClose();
            onReset();
        },
        handleApply = () => {
            handleClose();
            // onApply();
        };

    return (
        <CatalogFilterProvider
            data={ {
                hasSelectedValues,
                setHasSelectedValues,
            } }
        >
            <Box>
                <Box ref={buttonRef}>
                    { isSelected
                        ? <ButtonGroup
                            disableElevation
                            variant="contained"
                            className="active-filter-button"
                        >
                            <Button onClick={ handleClick } >
                                <CheckIcon/>
                                { content.label }
                            </Button>

                            <Button
                                title={ microcopy.get('catalog.filter_v2.reset', 'Filters wissen') }
                                onClick={ handleReset }
                            >
                                <CloseIcon/>
                            </Button>
                        </ButtonGroup>
                        : <Button
                            className="filter-button"
                            variant="outlined"
                            onClick={ handleClick }
                        >
                            { content.label }

                            { open ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                        </Button>
                    }
                </Box>

                <Popover
                    className="filter-popover v2"
                    open={ open }
                    anchorEl={ anchorEl }
                    onClose={ handleClose }
                    anchorOrigin={ {
                        vertical: 'bottom',
                        horizontal: 'center',
                    } }
                    transformOrigin={ {
                        vertical: 'top',
                        horizontal: 'center',
                    } }
                >
                    <Stack className="filter-popover-content">
                        {/*{ content.tooltip &&*/}
                        {/*    <Stack className="filter-popover-header" direction="row" justifyContent="flex-end">*/}
                        {/*        <Button className="action-filter-button" variant="text">*/}
                        {/*            { microcopy.get('catalog.filter_v2.more_info', 'Meer informatie') }*/}
                        {/*        </Button>*/}

                        {/*        <Tooltip title={ content.label } content={ content.tooltip } code={ content.code }/>*/}
                        {/*    </Stack>*/}
                        {/*}*/}
                        <Box className="filter-popover-main">
                            <Box>
                                { content.description &&
                                    <Box sx={ { mt: 2, mb: 2, paddingLeft: 4.4 } }>
                                        { content.description }
                                    </Box>
                                }
                                { children }
                            </Box>
                        </Box>

                        <Stack
                            className="filter-popover-footer"
                            direction="row"
                            justifyContent="flex-end">
                            <Button
                                className="action-filter-button"
                                variant="text"
                                onClick={ handleReset }
                                disabled={ !isSelected }
                            >
                                { microcopy.get('catalog.filter_v2.reset', 'Filters wissen') }
                            </Button>

                            <Button
                                className="action-filter-button"
                                variant="contained"
                                onClick={ handleApply }
                                disabled={ !hasSelectedValues }
                            >
                                {/*{ microcopy.get('catalog.filter_v2.apply', 'Opslaan') }*/}
                                { microcopy.get('catalog.filter_v2.show_results', 'Toon resultaten') }
                            </Button>
                        </Stack>
                    </Stack>
                </Popover>
            </Box>
        </CatalogFilterProvider>
    );
};

export default FilterV2;