import { Stack, Typography } from '@mui/material';
import { Price, priceSymbol } from '@components/Common/index';
import React from 'react';
import { useMicrocopy, useCatalogV2 } from '@hooks/common';
import { mapProductLine, productLines } from '@helpers';

const getPriceText = (productLine: string, productArticleGroup: string): string => {
    const microcopy = useMicrocopy();

    if (productArticleGroup === 'STD' && mapProductLine(productLine) === productLines.flexscreen)
        return microcopy.get('catalog.price.regular_product', 'Price per piece');

    switch (mapProductLine(productLine)) {
        case productLines.stairs:
            return microcopy.get('catalog.price.stairs', 'Starting price');
        case productLines.flexscreen:
        case productLines.insect:
            return microcopy.get('catalog.filter.price.insects_title', 'Starting price');
        case productLines.worktops:
            return microcopy.get('catalog.price.worktops', 'From');
        case productLines.cabinets:
            return microcopy.get('catalog.price.cabinets', 'From');
        default:
            return microcopy.get('catalog.filter.price.title', 'Per m²');
    }
};

export interface ProductPriceProps {
    price: number,
    discount: number,
    productLine?: string,
    productArticleGroup?: string,
    priceText?: string,
    withSymbol?: boolean
}

const ProductPrice = ({
    price,
    discount,
    productLine,
    productArticleGroup,
    priceText = getPriceText(productLine ?? '', productArticleGroup ?? ''),
    withSymbol = false,
}: ProductPriceProps) => {
    const catalogV2 = useCatalogV2();

    return (
        <Stack direction="column" className="combined-price">
            <Typography component="div" className="price-text">
                { !catalogV2 && priceText }

                { !!discount &&
                    <>
                        { catalogV2
                            ? <Stack className="discount-price">
                                <Price
                                    price={ price }
                                    withSymbol={ false }
                                />
                            </Stack>
                            : <Typography component="s" sx={ { ml: 0.5 } }>
                                { withSymbol && `${ priceSymbol } ` }
                                { (price / 100).toFixed(2) }
                            </Typography>
                        }
                    </>
                }
            </Typography>

            <Typography variant="h5" className={ discount ? 'with-discount' : '' }>
                <Price
                    price={ discount ? price * (1 - discount / 100) : price }
                    withSymbol={ false }
                />
            </Typography>
        </Stack>
    );
};

export default ProductPrice;
